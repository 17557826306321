import { ErrorHandler, NgModule } from '@angular/core';
import { AuthGuard, AuthModule } from '@capturum/auth';
import { environment } from '@environments/environment';
import { ApiModule } from '@capturum/api';
import { LoginModule, LoginService } from '@capturum/login';
import { SharedModule } from '@capturum/shared';
import {
  CapturumHttpCodeInterceptor,
  CompleteModule,
} from '@capturum/complete';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseDetailClass } from '@core/classes/base-detail.class';
import { BaseListClass } from '@core/classes/base-list.class';
import { BaseDataService } from '@core/services/base-data.service';
import {
  API_DATE_BLACKLIST,
  API_DATE_PROPERTIES,
} from '@core/configs/app-date.config';
import { BaseFilterClass } from '@core/classes/base-filter.class';
import { FormBuilderModel } from '@capturum/builders/form-renderer';
import { BaseEntityClass } from '@core/classes/base-entity.class';
import { FORMLY_TYPES_MODULE } from '@shared/modules/formly-types/formly-types.module';
import { AuthService } from '@core/services/auth.service';
import { LGEAuthGuard } from '@core/guards/auth.guard';
import { LGELoginService } from '@core/services/login.service';
import { getErrorHandler } from '@core/errorHandlers/sentry.errorHandler';
import { NgxsModule } from '@ngxs/store';

function onAuthError(): void {
  window.location.href = `auth/login`;
}

@NgModule({
  declarations: [
    BaseDetailClass,
    BaseEntityClass,
    BaseListClass,
    BaseFilterClass,
  ],
  imports: [
    NgxPermissionsModule.forRoot(),
    NgxsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
      authService: AuthService,
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
      dateInterceptor: {
        dateProperties: API_DATE_PROPERTIES,
        blackListedURLs: API_DATE_BLACKLIST,
        blackListedStatusCodes: [401, 204],
        displayFormat: 'dd-MM-yyyy HH:mm:ss',
        sendFormat: 'yyyy-MM-dd HH:mm:ss',
      },
    }),
    LoginModule.forRoot({
      environment,
      defaultLanguage: 'en',
      productName: 'LG | Lead Platform',
      backgroundImageUrl: 'assets/images/background.png',
      logoImageUrl: 'assets/images/logo_with_text.png',
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot(
      {
        indexedDbModels: [FormBuilderModel],
        databaseName: 'lge',
        userSpecificDb: false,
        version: 1,
        environment,
        loaderText: 'Loading',
      },
      NgxPermissionsService,
      NgxPermissionsModule
    ),
    FORMLY_TYPES_MODULE,
  ],
  providers: [
    BaseDataService,
    CapturumHttpCodeInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapturumHttpCodeInterceptor,
      multi: true,
    },
    {
      provide: AuthGuard,
      useClass: LGEAuthGuard,
    },
    {
      provide: LoginService,
      useClass: LGELoginService,
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
  ],
})
export class CoreModule {}
