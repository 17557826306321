import { TranslateService } from '@ngx-translate/core';
import {
  PublicTranslationResolver,
  PublicTranslationService,
} from '@capturum/complete';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LgPublicTranslationResolver extends PublicTranslationResolver {
  public constructor(
    private readonly translateService: TranslateService,
    private readonly publicTranslateService: PublicTranslationService
  ) {
    super(publicTranslateService);
  }

  public setPublicTranslations(): Observable<any> {
    return this.publicTranslateService.loadTranslations();
  }

  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const isSpain = window.location.host.includes('.es');

    return this.setPublicTranslations().pipe(
      tap(() => {
        return this.translateService.use(isSpain ? 'es' : 'en');
      }),
      map(Boolean)
    );
  }
}
